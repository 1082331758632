@import "../../../styles/base/abstracts";

.hbs-split-topper {
  @include site-max-width;

  // Content
  .hbs-split-topper__content {
    @include grid-parent;
    padding-top: $spacing-xxl;

    .hbs-search-box__input {
      font-size: $fs-sm;
    }
  }

  .hbs-split-topper__content-child {
    @include grid-child;
  }

  .hbs-split-topper__title {
    @include h1;

    &.hbs-split-topper__title--long {
      @include fluid-type(
        (
          $bp-mobile: $fs-xl,
          $bp-desktop-xl: 60px,
        )
      );
      font-weight: $fw-semibold;
      line-height: $lh-h1;
    }

    &.hbs-split-topper__title--xl {
      @include fluid-type(
        (
          $bp-mobile: $fs-lg,
          $bp-desktop-xl: $fs-xxl,
        )
      );
      font-weight: $fw-semibold;
      line-height: $lh-h2;
    }
  }

  .hbs-split-topper__subtitle {
    @include body-text($ff-sans);
    color: $c-text-light;
    margin-top: $spacing-md;

    @include mq($bp-tablet) {
      margin-top: $spacing-lg;
    }
    @include mq($bp-mobile, $bp-desktop) {
      margin-bottom: $spacing-lg;
    }
  }

  // CTA
  .hbs-split-topper__cta {
    margin-top: $spacing-lg;
  }

  .hbs-split-topper__cta-button {
    margin-bottom: $spacing-xxl;
    width: 100%;

    @include mq($bp-tablet) {
      margin-bottom: 0;
      width: inherit;
    }
  }

  // Media
  .hbs-split-topper__media {
    background-color: $c-bg-complementary;
    display: flex;
    min-height: 50vh;

    .hbs-media-asset {
      @include padding($grid-padding-mobile * 8);
      background-color: $c-bg-complementary;
      display: flex;
      flex-direction: column;
      width: 100%;
      justify-content: center;
    }

    .hbs-media-asset__modal-trigger-wrapper {
      @include padding($grid-padding-mobile * 8);
      display: flex;
      flex-direction: column;
      width: 100%;
      justify-content: center;

      .hbs-media-asset__kaltura-text {
        position: absolute;
        bottom: calc($grid-padding-mobile * 8 + 65px / 2);
        left: calc($grid-padding-mobile * 8 + 65px + $grid-padding);
      }
    }
  }

  .hbs-split-topper__media-podcast {
    background-color: $c-bg-complementary;
    display: flex;
    min-height: 50vh;
    align-items: center;

    .hbs-media-asset {
      @include padding($grid-padding-mobile * 8);
      background-color: $c-bg-complementary;
      width: 100%;
    }

    .hbs-media-asset__wrapper:has(video) {
      aspect-ratio: 1;
      video {
        object-fit: cover;
      }
    }

    figcaption {
      padding-bottom: 0;
      margin-bottom: ($grid-padding-mobile * -2);
    }
  }

  @include mq($bp-desktop) {
    display: flex;

    .hbs-split-topper__content {
      padding-top: $spacing-xxl;
      padding-bottom: $spacing-xxl;
      width: 50%;
    }

    .hbs-split-topper__content-child {
      @include padding(#{calc($grid-padding * 12 - $spacing-xxl)} x);
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .hbs-split-topper__media,
    .hbs-split-topper__media-podcast {
      min-height: 60vh;
      width: 50%;
      position: relative;
      padding-top: 0;

      &::before {
        @include pseudo(-100vh x 0 0);
        width: 50vw;
        background-color: $c-bg-complementary;
        pointer-events: none;
      }

      .hbs-media-asset,
      .hbs-media-asset__modal-trigger-wrapper {
        @include padding($grid-padding * 4 $grid-padding * 8 $grid-padding * 8);
        .hbs-media-asset__kaltura-text {
          position: relative;
          margin-top: calc((65px + $spacing-sm - 20px) * -1);
          margin-left: calc(65px + $grid-gutter-mobile + $spacing-sm);
          bottom: inherit;
          left: inherit;
        }
      }
    }
  }

  @include mq($bp-desktop) {
    .hbs-split-topper__media,
    .hbs-split-topper__media-podcast {
      .hbs-media-asset {
        @include padding($grid-padding * 4 $grid-padding * 8 $grid-padding * 8);
      }

      figcaption {
        padding-bottom: 0;
        margin-bottom: ($grid-padding-mobile * -6);
      }
    }
  }

  @include mq($bp-desktop-lg) {
    .hbs-split-topper__content {
      padding-right: 0;
    }

    .hbs-split-topper__content-child {
      @include padding(#{calc($grid-padding * 8 - $spacing-xxl)} x);
      margin-left: $col-2;
      width: $col-12;
    }

    .hbs-split-topper__cta {
      margin-top: $spacing-xxl;
    }
  }

  // Article
  // -------------------------------------------
  .hbs-split-topper__content-child--article {
    @include grid-child;
    align-items: center;
    display: flex;
    flex-direction: column;

    @include mq($bp-tablet) {
      padding-top: $grid-padding * 4;
    }
    @include mq($bp-desktop) {
      padding-top: $grid-padding * 8;
    }
  }

  .hbs-split-topper__overline {
    margin-bottom: $spacing-xs;
    text-align: center;
  }

  .hbs-split-topper__title--article {
    @include h2;
    margin-bottom: $spacing-md;
    text-align: center;

    @include mq($bp-tablet, $bp-desktop) {
      @include h2;
    }

    @include mq($bp-desktop) {
      @include padding(x $spacing-lg);
    }

    @include h2;

    &.hbs-split-topper__title--long {
      @include mq($bp-tablet, $bp-desktop) {
        @include h3;
      }
      @include mq($bp-desktop) {
        @include h3;
      }
    }

    &.hbs-split-topper__title--xl {
      @include mq($bp-tablet, $bp-desktop) {
        @include h3;
      }
      @include mq($bp-desktop) {
        @include h3;
      }
    }
  }

  .hbs-split-topper__meta {
    color: $c-text-light;
    max-width: 30em;
    text-align: center;
  }

  .hbs-split-topper__excerpt {
    @include body-text($ff-sans);
    color: $c-text-light;
    margin-bottom: $grid-padding-mobile * 4;
    margin-top: $spacing-xxl;
    text-align: center;

    @include mq($bp-tablet, $bp-desktop) {
      @include baseline-text;
    }

    @include mq($bp-desktop) {
      margin-top: auto;
      padding-top: $spacing-xxxl;
    }

    @include mq($bp-desktop-xl) {
      @include margin(x nested-cols(1, 8));
    }
  }

  .hbs-split-topper-wrapper--spot-theme & {
    .hbs-split-topper__media,
    .hbs-split-topper__media-podcast {
      background-color: currentColor;

      &::before {
        background-color: currentColor;
      }

      .hbs-media-asset {
        background-color: currentColor;
      }
    }
  }
}

// Search
// -------------------------------------------

.hbs-split-topper-wrapper--search {
  h1 {
    @include h2;
  }

  .hbs-search-box__dropdown {
    margin-top: $spacing-lg;

    @include mq($bp-tablet) {
      margin-top: $spacing-xl;
    }
  }
}

.hbs-split-topper__media-link {
  display: flex;
  text-decoration: none;

  @include hover {
    @include subtle-link($target: ".hbs-split-topper__media-highlight");
  }
}

.hbs-split-topper__media-highlight {
  color: $c-text-light;
  font-size: $fs-xxs;

  & > p {
    display: inline;
  }

  @include mq($bp-tablet) {
    max-width: $col-14;
  }
}

.hbs-split-topper__media.hbs-split-topper__media {
  figcaption {
    background-color: transparent;
  }
}

.hbs-split-topper__link-arrow {
  @include size(0.7em);
  margin-left: $spacing-xxs;
  vertical-align: middle;
}
